import React from "react"

import HubspotForm from "react-hubspot-form"

//  const aireBackup = () => {
//    return (
//    <>
//       <div
//       id="exit-intent"
//       className="popup-overlay modal w-full h-full top-0 left-0 flex items-center justify-center"
//     >
//       <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
//       <div className="exit-content relative modal-container bg-white w-11/12 md:w-8/12 md:max-w-md lg:max-w-6xl xl:max-w-4xl mx-auto rounded-xl shadow-lg z-50 overflow-y-auto">
//         <button
//           className="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50"
//           onClick={closepopup}
//         >
//           <svg
//             className="fill-current text-gray-500"
//             width="30"
//             height="30"
//             viewBox="0 0 18 18"
//           >
//             <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
//           </svg>
//         </button>
//         <div className="modal-content text-left relative overflow-hidden">
//           <div className="md:flex relative">
//             <div className="md:w-6/12 mob">
//               <img
//                 src="https://res.cloudinary.com/vantagecircle/image/upload/v1644995802/gatsbycms/uploads/2022/02/Emotional_connect.png"
//                 alt="Aire Redefine Your Recognition Program with AIRᵉ Quality Score"
//               />
//             </div>
//             <div className="md:w-8/12 p-10">
//               <p className="text-2xl md:text-3xl text-gray-500 font-bold" style={{marginTop: "15px"}}>
//                 Check if your current Rewards & Recognition Strategy is in Sync With the Global Benchmarks with a<br/> <span className="text-orange">Free Personalized Assessment.</span>
//               </p>
//               <p className="py-3">A detailed report will also be shared with you after the assessment</p>
//               <div className="rounded-sm w-11/12 md:w-9/12 mt-7">
//                 {/* <HubspotForm
//                   portalId="6153052"
//                   formId="9516ee95-0d36-4f9e-9340-2018a65ef260"
//                   onSubmit={() => console.log("Submit!")}
//                   onReady={form => console.log("Form ready!")}
//                   loading={<div>Loading...</div>}
//                 /> */}
//                 <a href="/tools/aire-calculator"  id="aire-link-btn" rel="noopener noreferrer" target="_blank" className="vc-btn-orange-v2 px-10">Take the AIRe Assessment</a>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </>
//   )
// }

const ExitIntent = ({ exitintent, closepopup }) => {
  return (
    <>
      <div
        id="exit-intent"
        className="popup-overlay modal w-full h-full top-0 left-0 hidden flex items-center justify-center"
      >
        <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
        <div className="exit-content relative modal-container  w-11/12  md:w-8/12 md:max-w-md lg:max-w-6xl xl:max-w-4xl mx-auto rounded-xl shadow-md z-50 overflow-y-auto px-0 border-2 border-white  ">
          <button
            className="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50"
            onClick={closepopup}
          >
            <svg
              className="fill-current text-white bg-purple-lighter rounded-full p-1 hover:transform hover:scale-125"
              width="20"
              height="20"
              viewBox="0 0 18 18"
            >
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
            </svg>
          </button>
          {/* <div className="absolute -top-2 right-96">
            <img
              src="https://res.cloudinary.com/vantagecircle/image/upload/w_160/gatsbycms/uploads/2023/01/Oval-top.png"
              width="120"
              alt="Global Industry Report On Impact of Employee engagement driving Diversity and Inclusion"
            />
          </div>
          <div className="absolute bottom-0 left-24">
            <img
              src="https://res.cloudinary.com/vantagecircle/image/upload/w_110/gatsbycms/uploads/2023/01/Oval-bottom.png"
              width="120"
              alt="Global Industry Report On Impact of Employee engagement driving Diversity and Inclusion"
            />
          </div> */}
          <div className="modal-content text-left p-10 h-500 bg-purple-30 relative overflow-hidden">
            <div className="flex space-x-6 relative items-center h-full">
              <div className="w-full h-full flex justify-center items-center">
                <div className="flex flex-col bg-white shadow-sm p-5 rounded-sm w-10/12 buyersdwnBtn">
                  <div className="text-center text-indigo-100 pb-5">
                    5K+ downloads by HR Professionals across the globe!
                  </div>
                  <HubspotForm
                    region="na1"
                    portalId="6153052"
                    formId="9516ee95-0d36-4f9e-9340-2018a65ef260"
                    onSubmit={() => console.log("Submit!")}
                    onReady={form => console.log("Form ready!")}
                    loading={<div>Loading...</div>}
                  />
                </div>
              </div>
              <div className="flex flex-col w-full h-full">
                <div>
                  <div class="text-3xl font-medium text-indigo-100 ">
                    A Comprehensive <br />
                    <span className="text-orangeText">
                      {" "}
                      Buyer's Guide For Rewards <br />& Recognition Program.
                    </span>
                  </div>
                  <div className=" mt-4 text-indigo-100 ">
                    A resource expertly crafted for your <br /> company's
                    personality!
                  </div>
                </div>
                <div className="w-full flex justify-center transform translate-y-9 relative ">
                  <img
                    className="w-9/12"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1679911238/gatsbycms/uploads/2023/08/buyers-Guide-mockup.png"
                    alt=" Buyer's Guide For Rewards"
                  />
                  <div className="h-28 w-28 bg-purple-lighter rounded-full absolute top-52 -left-20 "></div>
                  <div class="h-16 w-16 bg-purple-lighter rounded-full absolute top-0 right-0"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ExitIntent
