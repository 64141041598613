import React, { useState } from "react"

const RnRnav = ({ navProperty }) => {
  const [mobileDropdown, setmobileDropdown] = useState(false)
  const [mobileProductsOpen, setmobileProductsOpen] = useState(false)
  const [mobileResourcesOpen, setmobileResourcesOpen] = useState(false)

  const showmobileDropdown = () => {
    setmobileDropdown(!mobileDropdown)
  }

  const handleMobileProducts = () => {
    setmobileProductsOpen(!mobileProductsOpen)
    setmobileResourcesOpen(false)
  }

  const handleMobileResources = () => {
    setmobileResourcesOpen(!mobileResourcesOpen)
    setmobileProductsOpen(false)
  }

  return (
    <div className={`w-full ${navProperty.bgColor} ${navProperty.shadow} flex place-content-center z-50 py-2`}>
      <div className="w-11/12 2xl:max-w-7xl flex justify-between px-4 2xl:px-0 items-center">
        <div className="w-2/3 lg:w-1/3 py-3 lg:py-3">
          <div>
            <img
              className={`w-auto vc-logo transform ${navProperty.logoSize}`}
              src={`https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/${navProperty.logo}`}
              alt="Vantage Circle Logo"
              width="250"
              height="40"
            />
          </div>
        </div>
        <div className="hidden lg:flex justify-end items-center w-1/3 lg:w-11/12">
            <div className="grid grid-cols-4 justify-around gap-3 w-9/12">
                <div className={`${navProperty.tabClassName}`}><a className={`${navProperty.textColor}`} href={navProperty.ids.home}>{navProperty?.navBarOptions[0]}</a></div>
                <div className={`${navProperty.tabClassName}`}><a className={`${navProperty.textColor}`} href={navProperty.ids.features}>{navProperty?.navBarOptions[1]}</a></div>
                <div className={`${navProperty.tabClassName}`}><a className={`${navProperty.textColor}`} href={navProperty.ids.caseStudies}>{navProperty?.navBarOptions[2]}</a></div>
                <div className={`${navProperty.tabClassName}`}><a className={`${navProperty.textColor}`} href={navProperty.ids.testimonials}>{navProperty?.navBarOptions[3]}</a></div>
            </div>
          <div className={`px-0 xl:px-2 lg:w-2/6 xl:w-5/12 2xl:w-4/12 ${navProperty.buttonClassName}`}>
            <a
              href={navProperty?.buttonLink}
              className="text-gray-100 bg-newOrange py-2 lg:px-2 xl:px-6 rounded-full mx-1 transition duration-500 ease-in-out transform hover:-translate-y-1 flex justify-center items-center lg:text-base xl:text-lg"
            >
              {navProperty?.buttonName}
            </a>
          </div>
        </div>
        <div
          className="flex lg:hidden justify-center items-center"
          onClick={showmobileDropdown}
        >
          <div className="rounded-full bg-white p-2 cursor-pointer">
            <svg
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                stroke-linejoin="round"
                strokewidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <div
        className={
          mobileDropdown
            ? `overflow-x-hidden overflow-y-scroll absolute top-16 mt-1 md:mt-4 w-full left-0 flex flex-col justify-start items-start p-6 bg-white dropdown shadow-darkgray rounded-2xs z-50` +
              (mobileProductsOpen || mobileResourcesOpen ? " h-96" : "")
            : "hidden"
        }
      >
        <div className="w-full flex flex-col justify-start items-start">
            <div className="flex justify-end py-3"><a className="text-gray-500" href={navProperty.ids.home}>{navProperty?.navBarOptions[0]}</a></div>
            <div className="flex justify-end py-3"><a className="text-gray-500" href={navProperty.ids.features}>{navProperty?.navBarOptions[1]}</a></div>
            <div className="flex justify-end py-3"><a className="text-gray-500" href={navProperty.ids.caseStudies}>{navProperty?.navBarOptions[2]}</a></div>
            <div className="flex justify-end py-3"><a className="text-gray-500" href={navProperty.ids.testimonials}>{navProperty?.navBarOptions[3]}</a></div>  
          <div className="w-full flex justify-center">
            <a href={navProperty?.buttonLink} className="w-full md:w-1/4 vc-btn-orange-v2 my-2">
              {navProperty?.buttonName}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RnRnav
